import React, { Component } from 'react';
import { Link  } from "react-router-dom";
import { BsCalendar3, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { MdAccessTime } from "react-icons/md";
import { RiMapPin2Line } from "react-icons/ri";
import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { api } from "../Settings";
import axios from 'axios';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { FiEdit } from 'react-icons/fi';
const dayjs = require('dayjs');
const userData =  JSON.parse(localStorage.getItem("_userData"));
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 



class FeaturedNewsSlider extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          data: []
        }

        this.formatDate = this.formatDate.bind(this);
    }

    componentDidMount () {
        axios.get(api.getFeaturedNews).then(response => {
          this.setState({
            data: response.data
          })
        })
    }

    formatDate(param) {
        return dayjs(param).format('MMMM DD, YYYY')
    }
  
    

  render() {
    const { data } = this.state;
    return (
    <div className='slider_news relative z-0 my-16'>
    <h2  className="text-2xl md:text-3xl mb-8 font-semibold">Featured News</h2>
      { data && data.items &&
          <div className={`mb-16 relative`}>
             <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      navigation={{
        nextEl: ".slider_news .image-swiper-button-next",
        prevEl: ".slider_news .image-swiper-button-prev",
        disabledClass: "opacity-50 cursor-default"
      }}
      pagination={{ 
        clickable: true,
        el: `.swiper-container-pagination`,
        bulletClass: `swiper-pagination-bullet text-ash w-4 h-4`
     }}
    >
                {
                  data.items.map((item, index) => {
                    return(
                      <SwiperSlide key={index} className="md:flex items-center rounded-xl  pb-4 md:pb-0 overflow-hidden">

                      <div className={`relative bg-gray-300 bg-no-repeat bg-cover bg-center group w-full md:w-5/12 h-[240px] lg:h-[300px]`} style={{ backgroundImage: `url("${item.image}")` }}><Link to={`/news/${item.hash}`} className="absolute w-full h-full"></Link></div>
                      <div className='w-full md:w-7/12 md:pl-10 pt-2 md:pt-0'>
                          <h3 className="text-xl md:text-2xl"><Link to={`/news/${item.hash}`} className="block">{item.title}</Link></h3>
                          <div className='md:flex text-xs xl:text-base  mt-3 items-center'>
                                    {
                                        item.date &&
                                        <span className='text-primary font-medium flex items-center mb-1 mr-4'><BsCalendar3 className='mr-2 text-lg' />{this.formatDate(item.date)}</span>
                                    }
                                    {
                                        item.time &&
                                        <span className='text-primary font-medium flex items-center mb-1 mr-4'><MdAccessTime className='mr-2 text-lg' /> {item.time}</span>
                                    }
                                    {
                                        item.location &&
                                        <span className='text-primary font-medium flex items-center mb-1'><RiMapPin2Line className='mr-2 text-lg' /> {item.location}</span>
                                    }
                                </div>
                                <Link to={`/news/${item.hash}`} className="block">
                                 <div className="text-sm line-clamp-3" dangerouslySetInnerHTML={{__html : item.description}}></div>
                                </Link>
                                <Link to={`/news/${item.hash}`} className="inline-block mt-4 btn text-primary border border-primary hover:bg-primary transition-all duration-300  py-2.5 px-6 rounded-md text-sm hover:text-white">
                                    Read more
                                </Link>
                            </div>
                            {
                                EDIT_ACCESS && item.editLink &&
                                <a href={item.editLink.replace("ITEM_ID", item.hash)} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm'><FiEdit className='text-lg' title="Edit" /></a>
                            }
                       
                        </SwiperSlide>
                    )
                  })
                }
                </Swiper>
                <div className='swiper-container-pagination text-center mt-2'></div>
                <div className='flex gap-2 absolute right-0 bottom-0 z-10'>
                    <span className='image-swiper-button-prev w-12 h-12 rounded-full bg-ash flex items-center justify-center text-primary text-xl'><BsChevronLeft /></span>
                    <span className='image-swiper-button-next  w-12 h-12 rounded-full bg-ash flex items-center justify-center text-primary text-xl'><BsChevronRight /></span>
                </div>

        </div>
      }
      </div>
    );
  }
}

export default FeaturedNewsSlider;