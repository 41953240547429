import React, { Component} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate, Navigate  } from "react-router-dom";
import LoginForm from "./../compornents/LoginForm";

const userData =  JSON.parse(localStorage.getItem("_userData"));
const ASSCESS_TOKEN = (userData) && userData.data.access_token;

function withParams(Component) {
  return props => <Component {...props} navigation={useNavigate()} />;
}

class Login extends Component {

    constructor (props) {
        super(props);  

        this.state = {
          componentData: []
        }

    }

   /*  componentDidMount () {
     
      if(ASSCESS_TOKEN){
        console.log(ASSCESS_TOKEN);
      
      }

    } */


  render(){

    if (ASSCESS_TOKEN) {
      return <Navigate to="/" />;
    }
    
    return (
      <HelmetProvider>
        <div>
        <Helmet>
          <title> Admin - DashBoard </title>
        </Helmet>
        <div className="container flex items-center justify-center mx-auto px-8 h-full h-screen w-full">
              <LoginForm  />
        </div>
        </div>
        </HelmetProvider>
    )
  }
}

export default withParams(Login);
