import React, { Component } from 'react';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroller';
import { BsCaretDownFill } from "react-icons/bs";
import { api } from "../Settings";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import Loader from "./Loader";
import ApiError from "./ApiError";
import EmptyData from "./EmptyData";
import { FiEdit } from 'react-icons/fi';

const userData =  JSON.parse(localStorage.getItem("_userData"));
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 



class FAQList extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          data: [],
          isLoading: false,
          isError: false,
          params: {},
          hasMore: true,
          loading: false,
          loadedCount:0,
          total:0,
          pageSize: 25
        }

        this.renderData = this.renderData.bind(this);
        this.getData = this.getData.bind(this);
        this.fetch = this.fetch.bind(this);
    }

    componentDidMount () {

      const that = this;
      that.setState({  isLoading: true, isError: false })

      this.getData(0, true);

    }
    
    getData(count, reset){
       
      if (this.state.loading) {
          return;
      }

      if (this.state.loadedCount !== 0 && this.state.total < this.state.loadedCount ) {
          this.setState({ hasMore: false })
          return;
      }else{
          this.setState({ hasMore: true })
      }
      
      this.setState({ loading: true })

      if(count === 0 ){
          this.setState({ loadedCount: 0  })
      }

      if(reset){
          this.setState({ items: [], loadedCount: 0  }, () => { this.fetch(reset) })
      }else if(this.state.loadedCount === 0){
          this.setState({ loadedCount: 25 },() => { this.fetch(reset) })
      }else{
          this.fetch(reset) 
      }

    }
    
    fetch(reset){

      const { navigation } = this.props;
      const params = this.state.params;
      const that = this;

      if(reset){
        that.setState({ isLoading: true, isError: false })
      }

      axios.get(api.getFAQItems, { params: { ...params, st: this.state.loadedCount } }).then(response => {

          //Set total
          const tot = response.data.total ? response.data.total : 0;
          this.setState({ total: tot });

          this.setState({ items: [ ...this.state.items, ...response.data.items ] },() => {

              this.setState({ isLoading: false, loading: false });
            

              //if(!reset){
                  const newCount = this.state.pageSize + this.state.loadedCount;
                  this.setState({ loadedCount: newCount })
             // }

          });

      })
      .catch(function (error) {

          if (error.response && error.response.status === 401 ) {
              navigation("/logout");
              navigation(0);
          }

          that.setState({ isLoading: false, isError: true , loading: false })
      })
    }

    renderData(){
      const { data,  items, hasMore, total, isError  } = this.state;
      return (
        items &&
        <>
          {
                total === 0 && !isError &&
                <EmptyData/>
          }
          {                
          total > 0 &&
            <InfiniteScroll
                  pageStart={0}
                  loadMore={this.getData}
                  initialLoad={false}
                  hasMore={hasMore}
                  loader={<div className="loader" key={0}><Loader /></div>}
              >
              <Accordion className="mb-8 md:mb-16" preExpanded={[0]}>
                  {   
                      
                      items.map((item, index) => {
                          return (
                            <AccordionItem  key={index} className="item border-b border-body/50">
                                <AccordionItemHeading >
                                    <AccordionItemButton  className=' relative flex items-center justify-between'>
                                        <h2 className="text-xl md:text-2xl py-4 font-bold ml-4">{item.title}</h2>
                                        <BsCaretDownFill className='text-primary mr-4' />
                                        {
                                          EDIT_ACCESS && item.editLink &&
                                          <a href={item.editLink.replace("ITEM_ID", item.hash)} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm'><FiEdit className='text-lg' title="Edit" /></a>
                                        }
                                    </AccordionItemButton>
                                  
                                </AccordionItemHeading>
                                <AccordionItemPanel className="content-box px-4 pb-8">
                                    <div className="text-sm" dangerouslySetInnerHTML={{__html : item.description}}></div>
                                </AccordionItemPanel>
                               
                          </AccordionItem>
                          );
                      })
                  }
                  </Accordion>
            </InfiniteScroll>
          }
        </>

      )
    }
    
  render() {
    const { isLoading, isError } = this.state;
    return (
      <>
        {isLoading ? <Loader /> : this.renderData()}
        {isError && <ApiError />}
      </>
    );
  }
}

export default FAQList;