import React, { Component } from 'react';
import { FiEdit } from 'react-icons/fi';
const userData =  JSON.parse(localStorage.getItem("_userData"));
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 

class Intro extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          data: []
        }

        this.imageAlign = props.imageAlign;

    }
    
    static getDerivedStateFromProps(props, state) {
        return {data: props.data };
    }

    render() {
        const { data } = this.state;
        return (
        <>
        { data && 
            <div className={`mb-8 md:mb-16 flex-wrap flex flex-col-reverse md:flex-row items-center relative ${this.imageAlign == "left" ? "flex-row-reverse" : ""}`}>
                <div className="content-box  mt-6 md:mt-0 lg:px-10 w-full md:w-7/12">
                    <h2 className="text-2xl md:text-3xl mb-6 font-semibold text-primary">{data.title}</h2>
                    <div className="text-sm" dangerouslySetInnerHTML={{__html : data.description}}></div>
                    { data.btnAction && 
                        <a href={data.btnAction} className="inline-block mt-10 btn bg-primary shadow-md py-3 px-5 rounded-md text-sm text-white hover:shadow-lg hover:opacity-90">
                        {data.btnLabel}
                        </a>
                    }
                </div>
                <div className="image-box rounded-2xl w-full md:w-5/12 h-[300px] xl:h-[380px] bg-gray-500 bg-no-repeat bg-cover bg-center"  style={{ backgroundImage: `url("${data.image}")` }}></div>
                {
                    EDIT_ACCESS && data.editLink &&
                    <a href={data.editLink} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm'><FiEdit className='text-lg' title="Edit" /></a>
                }
            </div>
        }
        </>
        );
    }
}

export default Intro;