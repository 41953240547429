import React, { Component } from 'react';
import { FiEdit } from 'react-icons/fi';
import { Link  } from "react-router-dom";

const userData =  JSON.parse(localStorage.getItem("_userData"));
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 

class FeaturedNav extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          data: []
        }
        this.imageAlign = props.imageAlign;
    }

    static getDerivedStateFromProps(props, state) {
      return {data: props.data };
    }
    

  render() {
    const { data } = this.state;
    return (
      <>
      { data && data.blocks &&
          <div className={`mb-16  ${this.imageAlign === "left" ? "" : ""}`}>
            <div className="grid gap-2 grid-cols-2 md:grid-cols-11">
                {
                  data.blocks.map((item, index) => {
                      return (
                        <div key={index} className={`md:col-span-${item.col} md:row-span-${item.row} relative bg-gray-300 bg-no-repeat bg-cover bg-center group pb-full ${item.col === 3  ? "md:h-0 md:pb-full" : ""} ${item.col === 5 ? "md:h-full md:pb-0" : ""} ${item.col === 6  ? "md:h-full md:pb-[50%]" : ""}`} style={{ backgroundImage: `url("${item.data.image}")` }}>
                           <Link to={item.url}  className="w-full h-full absolute top-0 left-0">
                            <h3 className="bottom-0 left-0 absolute w-full p-2 md:pb-4 md:pt-6 md:px-4 group-hover:pb-6 group-hover:from-body duration-300 transition-all text-lg md:text-xl text-white from-body/50 bg-gradient-to-t ">{item.data.title}</h3>
                          </Link>

                          {
                            EDIT_ACCESS && item.data.editLink &&
                            <a href={item.data.editLink} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm'><FiEdit className='text-lg' title="Edit" /></a>
                          }
                        </div>
                      );
                  })
                }


                {/* {
                  data.blocks.map((item, index) => {
                      return (
                        <div key={index} className={`md:col-span-${item.col} md:row-span-${item.row} relative bg-gray-300 bg-no-repeat bg-cover bg-center group pb-full ${item.col === 1 || item.row === 2 ? "md:h-0 md:pb-full" : ""} ${item.col === 2 && item.row === 1 ? "md:h-0 md:pb-[50%]" : ""}`} style={{ backgroundImage: `url("${item.data.image}")` }}>
                          <Link to={item.url} className="w-full h-full absolute top-0 left-0">
                            <h3 className="bottom-0 left-0 absolute w-full p-2 md:pb-4 md:pt-6 md:px-4 group-hover:pb-6 group-hover:from-body duration-300 transition-all text-lg md:text-xl text-white from-body/50 bg-gradient-to-t ">{item.data.title}</h3>
                          </Link>
                        </div>
                      );
                  })
                } */}
            </div>
        </div>
      }
      </>
    );
  }
}

export default FeaturedNav;