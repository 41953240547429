import React from 'react';
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { api } from "../../Settings";
import axios from 'axios';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

function withParams(Component) {
  return props => <Component {...props} navigation={useNavigate()} />;
}

class BarChart extends React.Component {

  constructor(props) {
    super(props)

    this.divRef = React.createRef()

    this.state = {
      isLoading: false,
      isError: false,
      labels: [],
      backgrounds: [],
      logins: [],
      dateFrom: this.props.dateFrom,
      dateTo: this.props.dateTo,
      componentData: []
    }

    this.csvData = []

    this.options = {
      fill: false,
      title: {
        display: false,
        text: 'Chart.js Line Chart'
      },
      tooltips: {
        mode: 'label',
      },
      hover: {
        mode: 'nearest',
        intersect: true
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            boxWidth: 100,
            padding: 30,
          },
          position: 'bottom',
          align: 'end'
        },
        title: {
          display: false,
          text: this.props.legend,
        },

      },
      scales: {
        y: {
          display: true,
          title: {
            display: true,
            text: 'value',
            font: {
              size: 14 //this change the font size
            }
          },
          grid: {
            display: false,
          },
        },
        x: {
          display: true,
          title: {
            display: true,
            text: this.props.rate,
            font: {
              size: 14 //this change the font size
            }
          },
          grid: {
            display: false,
          },

        }
      }
    };

    this.handleData = this.handleData.bind(this)
    this.handleRequest = this.handleRequest.bind(this)

  }

  componentDidMount() {

    this.handleRequest()

  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.state.dateFrom == this.props.dateFrom) && (this.state.dateTo == this.props.dateTo)) {
      return
    }

    this.handleDateRangeRequest()
  }

  handleDateRangeRequest() {
    const that = this;

    const { navigation } = this.props;
    axios.get(api.getDailyDownloadsAndLogins, { params: { std: this.props.dateFrom, end: this.props.dateTo } }).then(response => {
      const { data } = response.data
      this.props.handleDownloadDay(data)
      this.handleData(data)
    })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          navigation("/logout");
          navigation(0);
        }

        // that.setState({ isLoading: false, isError: true , loading: false })
      })
  }

  handleRequest() {
    console.log('request handle')
    const that = this;
    const { navigation } = this.props;
    // that.setState({ isLoading: true, isError: false })
    axios.get(api.getDailyDownloadsAndLogins).then(response => {
      const { data } = response.data
      this.csvData = data;
      this.props.handleDownloadDay(data)
      this.handleData(data)
      // this.setState({ isLoading: false });
    })
      .catch(function (error) {
        navigation("/logout");
        navigation(0);
        that.setState({ isLoading: false, isError: true })
      })

  }

  handleData(data) {
    this.setState({
      labels: data.map(dat => dat.date),
      downloads: data.map(dat => dat.downloads),
      logins: data.map(dat => dat.logins),
      dateFrom: this.props.dateFrom,
      dateTo: this.props.dateTo
    })
  }

  render() {
    return (
      <div style={{ position: "relative", margin: "auto", width: '100%' }} className="flex">
        <Bar options={this.options} width={1000} height={300}
          data={{
            labels: this.state.labels,
            datasets: [
              {
                fill: false,
                label: 'Downloads',
                data: this.state.downloads,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              },
              {
                fill: false,
                label: 'Logins',
                data: this.state.logins,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 35, 0.5)',
              },
            ],
          }} />
      </div>
    )
  }

}

export default withParams(BarChart);
