import React, { Component } from "react";
import "./Analytics.scss"
import { api } from "../../Settings"

export default class Analytics extends Component {
  
    constructor(props) {
        super(props)
        this._userData = localStorage.getItem("_userData");
        this._userData = JSON.parse(this._userData)
        // console.log(this._userData.analytics_url)
        // this.iframe = `https://analytics.dammore.com/index.php?module=Widgetize&action=iframe&moduleToWidgetize=Dashboard&actionToWidgetize=index&idSite=26&period=year&date=today&token_auth=5defb487857490d3919327cc2db9e1ca`
        this.iframe = `${this._userData.analytics_url}`
    
    }

    render() {
        return (
            <div className="overflow-x-hidden" >
                <iframe src={this.iframe} title="analytics page" className=" w-full overflow-y-auto h-[1350px]" scrolling-x="no"></iframe>
            </div>
        )
    }
}