import React, { Component } from 'react';
import axios from 'axios';
import { Link  } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import { BsCalendar3 } from "react-icons/bs";
import {  MdAccessTime } from "react-icons/md";
import {  RiMapPin2Line } from "react-icons/ri";
import { api } from "../Settings";
import NewsFilter from './NewsFilter';
import Loader from "./Loader";
import ApiError from "./ApiError";
import EmptyData from "./EmptyData";
import { FiEdit } from 'react-icons/fi';
const dayjs = require('dayjs');

const userData =  JSON.parse(localStorage.getItem("_userData"));
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 


class EventsGrid extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          data: [],
          isLoading: false,
          isError: false,
          params: {},
          hasMore: true,
          loading: false,
          loadedCount:0,
          total:0,
          pageSize: 25
        }

        this.formatDate = this.formatDate.bind(this);
        this.getDiff = this.getDiff.bind(this);
        this.updateSelection = this.updateSelection.bind(this);
        this.renderData = this.renderData.bind(this);
        this.getData = this.getData.bind(this);
        this.fetch = this.fetch.bind(this);
    }

    componentDidMount () {

      const { navigation } = this.props;
      const that = this;
      that.setState({  isLoading: true, isError: false })

      this.getData(0, true);

       /*  axios.get(api.getEventsItems).then(response => {
          this.setState({
            data: response.data
          })

          that.setState({ isLoading: false })

        }).catch(function (error) {

          if (error.response && error.response.status === 401 ) {
              navigation("/logout");
              navigation(0);
          }

          that.setState({ isLoading: false, isError: true });
      });  */
    }

    formatDate(param) {
        return dayjs(param).format('MMMM DD, YYYY')
    }

    getDiff(param) {

        const evtDate = dayjs(param);
        const today = dayjs();

        let hours = evtDate.diff(today, 'hours');
        const days = Math.floor(hours / 24);
        //hours = hours - (days * 24);

        if(days > 0){
            return ( <span className='text-red-700 border border-red-700 py-1 px-4 rounded-3xl whitespace-nowrap' >In {days} Days</span>);
        }

    }

    
    updateSelection(filters) {

      const obj = {};

      if((filters && filters.month.code) || (filters && filters.year.code)){
          obj.qf = [];
          obj.qf.push('start_date');

          if(filters.year.code && filters.month.code){
            obj.q = filters.year.code + '-' + filters.month.code;
          }else if(filters.year.code){
            obj.q = filters.year.code;
          }else if(filters.month.code){
            obj.q = '-' + filters.month.code + '-';
          }
      }else{
          obj.qf = null;
          obj.q = null;
      }

      if(filters && filters.sort.code){
        
        let sortParams = filters.sort.code.split('-');
        obj.sd = sortParams[0];
        obj.sp = sortParams[1];

      }else{
        obj.sd = null;
        obj.sp = null;
      }

      if(filters && filters.tags.items){

          const keys = [];
          filters.tags.items.map((tag, i) => (
            keys.push(tag.value)
          ));

          obj.tags = keys;
          
      }else{
          obj.tags = null
      }

      if(filters){
          this.setState({ params: { ...this.state.params, ...obj },  loadedCount: 0 },() => {
            this.getData(0, true)
          });
      }else{
          this.setState({ params: {}, loadedCount: 0},() => {
            this.getData(0, true)
          });
      }

    }

    
    getData(count, reset){
       
      if (this.state.loading) {
          return;
      }

      if (this.state.loadedCount !== 0 && this.state.total < this.state.loadedCount ) {
          this.setState({ hasMore: false })
          return;
      }else{
          this.setState({ hasMore: true })
      }
      
      this.setState({ loading: true })

      if(count === 0 ){
          this.setState({ loadedCount: 0  })
      }

      if(reset){
          this.setState({ items: [], loadedCount: 0  }, () => { this.fetch(reset) })
      }else if(this.state.loadedCount === 0){
          this.setState({ loadedCount: 25 },() => { this.fetch(reset) })
      }else{
          this.fetch(reset) 
      }

    }
    
    fetch(reset){

      const { navigation } = this.props;
      const params = this.state.params;
      const that = this;

      if(reset){
        that.setState({ isLoading: true, isError: false })
      }

      axios.get(api.getEventsItems, { params: { ...params, st: this.state.loadedCount } }).then(response => {

          //Set total
          const tot = response.data.total ? response.data.total : 0;
          this.setState({ total: tot });

          this.setState({ items: [ ...this.state.items, ...response.data.items ] },() => {

              this.setState({ isLoading: false, loading: false });
            

              if(!reset){
                  const newCount = this.state.pageSize + this.state.loadedCount;
                  console.log(newCount);
                  this.setState({ loadedCount: newCount })
              }

          });

      })
      .catch(function (error) {

          if (error.response && error.response.status === 401 ) {
              navigation("/logout");
              navigation(0);
          }

          that.setState({ isLoading: false, isError: true , loading: false })
      })
    }

    filterData(){

        const { navigation } = this.props;
        const that = this;
        that.setState({  isLoading: true, isError: false })
  
        axios.get(api.getEventsItems, { params: this.state.params }).then(response => {
            this.setState({
              data: response.data
            })

            that.setState({ isLoading: false })
        })
        .catch(function (error) {

            if (error.response && error.response.status === 401 ) {
                navigation("/logout");
                navigation(0);
            }

            that.setState({ isLoading: false, isError: true });
        }); 

    }

    
    renderData(){
      const { data,  items, hasMore, total, isError  } = this.state;
      return (
        items &&
        <>
          {
                total === 0 && !isError &&
                <EmptyData/>
          }
          {                
          total > 0 &&
            <InfiniteScroll
                  pageStart={0}
                  loadMore={this.getData}
                  initialLoad={false}
                  hasMore={hasMore}
                  loader={<div className="loader" key={0}><Loader /></div>}
              >
              <div className="mb-8 md:mt-12 md:mb-16 grid grid-cols-1 md:grid-cols-2 gap-8 xl:gap-20">
                  {   
                      
                      items.map((item, index) => {
                          return (
                          <div key={index} className="item relative">
                              <div className="rounded-2xl image-box w-full h-[240px] xl:h-[270px] 2xl:h-[320px] bg-gray-500 bg-no-repeat bg-cover bg-center"  style={{ backgroundImage: `url("${item.image}")` }}></div>
                              <div className="content-box mt-4">
                                  <div className='flex items-start justify-between'>
                                    <h2 className="text-xl md:text-2xl mb-4 font-bold">{item.title}</h2>
                                    {this.getDiff(item.date)}
                                  </div>
                                  <span className='text-primary font-medium flex items-center mb-1'><BsCalendar3 className='mr-2 text-lg' />{this.formatDate(item.date)}</span>
                                  <div className='flex mt-2'>
                                      <span className='text-primary font-medium flex items-center mb-1 mr-4'><MdAccessTime className='mr-2 text-lg' /> {item.time}</span>
                                      <span className='text-primary font-medium flex items-center mb-1'><RiMapPin2Line className='mr-2 text-lg' /><span dangerouslySetInnerHTML={{__html : item.location}}></span></span>
                                  </div>
                                  <div className="text-sm line-clamp-4" dangerouslySetInnerHTML={{__html : item.description}}></div>
                                  { item.hash && 
                                    <>
                                        <Link to={`/events/${item.hash}`} className="inline-block mt-4 btn text-primary border border-primary hover:bg-primary transition-all duration-300  py-3 px-6 rounded-md text-sm hover:text-white">
                                          {item.btnLabel}
                                        </Link>
                                    </>
                                  }
                              </div>
                              {
                                  EDIT_ACCESS && item.editLink &&
                                  <a href={item.editLink.replace("ITEM_ID", item.hash)} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm'><FiEdit className='text-lg' title="Edit" /></a>
                              }
                          </div>
                          );
                      })
                  }
              </div>
            </InfiniteScroll>
          }
        </>

      )
    }
    
  render() {
    const { isLoading, isError } = this.state;
    return (
      <>
        <NewsFilter mode="events" updateSelection={this.updateSelection}  />
        {isLoading ? <Loader /> : this.renderData()}
        {isError && <ApiError />}
      </>
    );
  }
}

export default EventsGrid;