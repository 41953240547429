import React, { Component } from 'react';
import { FiEdit } from 'react-icons/fi';
const userData =  JSON.parse(localStorage.getItem("_userData"));
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 


class FeatureBox extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          data: []
        }

        this.imageAlign = props.imageAlign;

    }
    
    static getDerivedStateFromProps(props, state) {
        return {data: props.data };
    }

    render() {
        const { data } = this.state;
        return (
        <>
        { data && 
            <div className={`mb-8 md:mb-16 flex-wrap md:flex-nowrap md:flex-row flex-col-reverse flex items-center text-white bg-primary relative ${this.imageAlign == "left" ? "flex-row-reverse" : ""}`}>
                <div className="content-box p-4 md:py-0 xl:px-10 2xl:px-20 w-full md:w-1/2">
                    <h2 className="text-2xl xl:text-3xl xl:mb-6 font-semibold ">{data.title}</h2>
                    <div className="text-sm line-clamp-10" dangerouslySetInnerHTML={{__html : data.description}}></div>
                    { data.btnAction && 
                        <a href={data.btnAction} className="inline-block mt-10 btn bg-primary shadow-md py-3 px-5 rounded-md text-sm text-white hover:shadow-lg hover:opacity-90">
                        {data.btnLabel}
                        </a>
                    }
                </div>
                <div className="image-box w-full md:w-1/2 h-[300px] md:h-[400px] xl:h-[480px]  2xl:h-[576px] bg-gray-500 bg-no-repeat bg-cover bg-center"  style={{ backgroundImage: `url("${data.image}")` }}></div>
                {
                    EDIT_ACCESS && data.editLink &&
                    <a href={data.editLink} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm'><FiEdit className='text-lg' title="Edit" /></a>
                }
            </div>
        }
        </>
        );
    }
}

export default FeatureBox;