import React, { Component } from 'react';
import { FiEdit } from 'react-icons/fi';
import { Link  } from "react-router-dom";

const userData =  JSON.parse(localStorage.getItem("_userData"));
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 


class ImageTextBoxes extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          data: []
        }
        this.imageAlign = props.imageAlign;
    }

    static getDerivedStateFromProps(props, state) {
      return {data: props.data };
    }
    
  render() {
    const { data } = this.state;
    return (
      <>
      { data && data.items &&
        <div className="items mt-12 md:mt-0">
            {
                data.items.map((item, index) => {
                    return (
                      <div  key={index} className={`mb-8 md:mb-20 relative flex-wrap flex items-center flex-col-reverse md:flex-row ${index % 2 === 1 ? "md:flex-row-reverse" : ""} ${index !== (data.items.length -1)  && "pb-8 md:pb-20"}`}>
                        <div className={`content-box mt-4 md:mt-0 w-full md:w-2/5  ${index % 2 == "1" ? "md:pl-10 xl:pl-20" : "md:pr-10 xl:pr-20"}`}>
                            <h2 className="text-2xl xl:text-4xl mb-4 md:mb-8 font-bold">{item.title}</h2>
                            <div className="text-sm line-clamp-4" dangerouslySetInnerHTML={{__html : item.description}}></div>
                            { item.btnAction && 
                              <>
                                  <Link to={`${item.url ? `${item.url}` : `${item.btnAction}` }`} className="inline-block mt-4 md:mt-8 btn text-primary border border-primary hover:bg-primary transition-all duration-300 py-3 px-8 rounded-md text-sm hover:text-white">
                                    {item.btnLabel}
                                  </Link>
                              </>
                            }
                        </div>
                        <div className="image-box w-full md:w-3/5 --h-300 --lg:h-[460px] bg-gray-500 bg-no-repeat bg-cover bg-center rounded-2xl  overflow-hidden"  data-style={{ backgroundImage: `url("${item.image}")` }}>

                          <img src={item.image} className="w-full h-auto block"/>
                        </div>
                        {
                          index !== (data.items.length -1) && 
                          <span className='w-2/3 absolute bottom-0 left-1/2 transform -translate-x-1/2 border-b'></span>
                        }
                        {
                            EDIT_ACCESS && item.editLink &&
                            <a href={item.editLink} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm'><FiEdit className='text-lg' title="Edit" /></a>
                        }
                      </div>
                    );
                })
            }
        </div>
                 
      }
      </>
    );
  }
}

export default ImageTextBoxes;