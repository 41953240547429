import React, { Component } from "react";
import { MdDownload, MdKeyboardArrowDown } from "react-icons/md";
import { CSVLink, CSVDownload } from "react-csv";
import BarChart from "./Bar";
import LineChart from "./Line";
import AdminLinks from './AdminLinks'
import { DateRangePicker } from 'rsuite';
import { api } from "../../Settings";
import "./Layout.scss"

export default class Layout extends Component {

    constructor(props) {
        super(props)

        this.heightRef = React.createRef();

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mmPrev = String(today.getMonth()).padStart(2, '0'); //January is 0!
        var mmToday = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = mmToday + '-' + dd + '-' + yyyy;
        var prevDay = mmPrev + '-' + dd + '-' + yyyy;

        this.state = {
            dateFrom: prevDay,
            dateTo: today,
            graphHeigh: 0,
            csvData: [],
            csvDataMonth: [],
            csvDataYear: []
        }

        this.handleDateRange = this.handleDateRange.bind(this);
        this.handleDateRangeMonthly = this.handleDateRangeMonthly.bind(this);
        this.handleDateRangeYearly = this.handleDateRangeYearly.bind(this);
        this.handleDownloadDay = this.handleDownloadDay.bind(this)
        this.handleDownloadMonth = this.handleDownloadMonth.bind(this)
        this.handleDownloadYear = this.handleDownloadYear.bind(this)

    }

    handleDateRange(dateRange) {
        let dateFrom = `${dateRange[0].getFullYear()}-${dateRange[0].getMonth() + 1}-${dateRange[0].getDate()}`
        let dateTo = `${dateRange[1].getFullYear()}-${dateRange[1].getMonth() + 1}-${dateRange[1].getDate()}`

        this.setState({
            dateFrom,
            dateTo,
            labels: [],
            backgrounds: [],
            logins: [],
            dateFrom: this.props.dateFrom,
            dateTo: this.props.dateTo
        })

        this.handleData = this.handleData.bind(this)

    }


    handleData(data) {
        this.setState({
            labels: data.map(dat => dat.date),
            downloads: data.map(dat => dat.downloads),
            logins: data.map(dat => dat.logins),
            dateFrom: this.props.dateFrom,
            dateTo: this.props.dateTo
        })
    }

    handleDateRangeMonthly(dateRange) {
        let dateFromMonthly = `${dateRange[0].getFullYear()}-${dateRange[0].getMonth() + 1}-${dateRange[0].getDate()}`
        let dateToMonthly = `${dateRange[1].getFullYear()}-${dateRange[1].getMonth() + 1}-${dateRange[1].getDate()}`

        this.setState({
            dateFromMonthly,
            dateToMonthly
        })

    }

    handleDateRangeYearly(dateRange) {
        let dateFromYearly = `${dateRange[0].getFullYear()}-${dateRange[0].getMonth() + 1}-${dateRange[0].getDate()}`
        let dateToYearly = `${dateRange[1].getFullYear()}-${dateRange[1].getMonth() + 1}-${dateRange[1].getDate()}`

        this.setState({
            dateFromYearly,
            dateToYearly
        })

    }

    componentDidMount() {
        this.updateWidth();
        window.addEventListener("resize", this.updateWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWidth);
    }

    componentDidUpdate() {
        this.updateWidth();
    }

    handleDownloadDay(csvData) {
        this.setState({
            csvData: csvData
        })
    }

    handleDownloadMonth(csvData) {
        this.setState({
            csvDataMonth: csvData
        })
    }

    handleDownloadYear(csvData) {
        this.setState({
            csvDataYear: csvData
        })
    }

    updateWidth = () => {
        const node = this.heightRef.current;
        const width = node ? node.offsetWidth : null;
        if (this.state.graphHeigh !== (width / 2)) {
            this.setState({ graphHeigh: width / 2 });
        }
    };

    render() {
        return (<>
            <AdminLinks />
            <div ref={this.heightRef} className="grid grid-cols-2 grid-rows-2 md:grid-cols-2 md:grid-rows-3 sm:grid-rows-3 lg:grid-cols-4 lg:grid-rows-2 gap-5">
                <div className="tile rounded-sm h-min bg-[#00717815] col-span-full p-5">
                    <div className="flex justify-start w-full flex-row p-0 items-left h-20 flex-wrap">
                        <div className="w-1/2 flex justify-start w-full flex-col">
                            <div className="w-auto mr-4">
                                <p className="mb-0 text-lg text-left font-semibold text-gray-700 lg:text-xl sm:px-2 xl:px-2 dark:text-gray-400">Partner's Analytics for Current Day</p>
                            </div>
                            <div className="flex flex-row">
                                <div className="w-30 mr-4 ">
                                    <p className="mb-0 text-sm font-normal text-gray-500 lg:text-sm sm:px-2 ">Total downloads: 816</p>
                                </div>
                                <div className="w-30 ">
                                    <p className="mb-0 text-sm font-normal text-gray-500 lg:text-sm sm:px-2 ">Total login: 333</p>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/2 flex items-start justify-end flex-row float-right">
                            <div className="w-auto p-0 mr-2 mb-1 mt-0">

                                <button className="bg-blue-200 text-white font-bold rounded text-gray-800 hover:text-blue-500 hover:bg-blue-300">
                                    <CSVLink data={this.state.csvData} filename={"DailySummary.csv"}>
                                        <MdDownload style={{ fontSize: '23px' }} className="text-blue-500 m-1.5 mr-2 ml-2 hover:text-blue-800" />
                                    </CSVLink>
                                </button>

                            </div>
                            <div>
                                <DateRangePicker placeholder="Select range" placement="auto" placementalign="left" onChange={(dateRange) => this.handleDateRange(dateRange)} />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center p-0 items-center h-auto flex-wrap">
                        <div className="w-full relative md:w-1/2 h-5 p-0 m-0  flex justify-end">

                            <div id="dropdown" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                                    <li>
                                        <a href="#" className="block px-4 py-2 hover: dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
                                    </li>
                                    <li>
                                        <a href="#" className="block px-4 py-2 hover: dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
                                    </li>
                                    <li>
                                        <a href="#" className="block px-4 py-2 hover: dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
                                    </li>
                                    <li>
                                        <a href="#" className="block px-4 py-2 hover: dark:hover:bg-gray-600 dark:hover:text-white">Sign out</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className="flex w-full p-0 h-min flex-wrap">
                        <div className="w-full m-auto h-min">
                            <BarChart legend={"Partner's Analytics for Current Day"}
                                dateFrom={this.state.dateFrom}
                                dateTo={this.state.dateTo}
                                className="w-full h-full"
                                rate={'Date'}
                                graphHeight={this.state.graphHeigh}
                                handleDownloadDay={this.handleDownloadDay} />
                        </div>

                    </div>

                </div>
                <div className="tile rounded-sm h-min bg-[#00717815] col-span-full sm:col-span-full md:col-span-full lg:col-span-full xl:col-span-2 p-5">
                    <div className="flex justify-start w-full flex-row p-0 items-left h-auto flex-wrap">
                        <div className="w-2/3 flex justify-start w-full flex-col">
                            <div className="w-auto mr-4">
                                <p className="mb-0 text-sm text-left font-semibold text-gray-700 lg:text-base sm:px-2 xl:px-2 dark:text-gray-400">Partner's Analytics for Current Month</p>
                            </div>
                            <div className="flex flex-row">
                                <div className="w-30 mr-4 ">
                                    <p className="mb-0 text-sm text-gray-500 lg:text-sm sm:px-2">Total downloads: 816</p>
                                </div>
                                <div className="w-30 ">
                                    <p className="mb-0 text-sm text-gray-500 lg:text-sm sm:px-2">Total login: 333</p>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/3 flex items-start justify-end flex-row float-right">
                            <div className="w-auto p-0 mr-2 mb-1 mt-0">

                                <button className="bg-blue-200 text-white font-bold rounded text-gray-800 hover:text-blue-500 hover:bg-blue-300">
                                    <CSVLink data={this.state.csvDataMonth} filename={"MonthlySummary.csv"}>
                                        <MdDownload style={{ fontSize: '23px' }} className="text-blue-500 m-1.5 mr-2 ml-2 hover:text-blue-800" />
                                    </CSVLink>
                                </button>

                            </div>
                            <div>
                                <DateRangePicker
                                    placeholder="Select range" placement="auto" placementalign="left" onChange={(dateRange) => this.handleDateRangeMonthly(dateRange)} />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center p-0 items-center h-auto flex-wrap">
                        <div className="w-full relative md:w-1/2 h-5 p-0 m-0  flex justify-end">

                            <div id="dropdown" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                                    <li>
                                        <a href="#" className="block px-4 py-2 hover: dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
                                    </li>
                                    <li>
                                        <a href="#" className="block px-4 py-2 hover: dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
                                    </li>
                                    <li>
                                        <a href="#" className="block px-4 py-2 hover: dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
                                    </li>
                                    <li>
                                        <a href="#" className="block px-4 py-2 hover: dark:hover:bg-gray-600 dark:hover:text-white">Sign out</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="flex justify-center w-full p-0 items-center flex-wrap">
                        <div className="w-full m-auto">
                            <LineChart
                                dateFrom={this.state.dateFromMonthly}
                                dateTo={this.state.dateToMonthly}
                                legend={"Partner's Analytics for Current Month"} rate={'Month'} className="w-auto"
                                dataLoginApi={api.getMonthlyDownloadsAndLogins}
                                handleDownloadMonth={this.handleDownloadMonth}
                                ranger={'Month'} />
                        </div>

                    </div>
                </div>
                <div className="tile rounded-sm h-min bg-[#00717815] col-span-full sm:col-span-full md:col-span-full lg:col-span-full xl:col-span-2 p-5">
                    <div className="flex justify-start w-full flex-row p-0 items-left h-auto flex-wrap">
                        <div className="w-1/2 flex justify-start w-full flex-col">
                            <div className="w-auto mr-4">
                                <p className="mb-0 text-sm text-left font-semibold text-gray-700 lg:text-base sm:px-2 xl:px-2 dark:text-gray-400">Partner's Analytics for Current Year</p>
                            </div>
                            <div className="flex flex-row">
                                <div className="w-30 mr-4 ">
                                    <p className="mb-0 text-sm font-normal text-gray-500 lg:text-sm sm:px-2">Total downloads: 816</p>
                                </div>
                                <div className="w-30 ">
                                    <p className="mb-0 text-sm font-normal text-gray-500 lg:text-sm sm:px-2">Total login: 333</p>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/2 flex items-start justify-end flex-row float-right">
                            <div className="w-auto p-0 mr-2 mb-1 mt-0">

                                <button className="bg-blue-200 text-white font-bold rounded text-gray-800 hover:text-blue-500 hover:bg-blue-300">
                                    <CSVLink data={this.state.csvDataYear} filename={"YearlySummary.csv"}>
                                        <MdDownload style={{ fontSize: '23px' }} className="text-blue-500 m-1.5 mr-2 ml-2 hover:text-blue-800" />
                                    </CSVLink>
                                </button>

                            </div>
                            <div>
                                <DateRangePicker placeholder="Select range" placement="auto" placementalign="left" onChange={(dateRange) => this.handleDateRangeYearly(dateRange)} />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center p-0 items-center h-auto flex-wrap">
                        {/* <div className="w-full md:w-1/2 p-0 m-0 ">
                            <MdDownload style={{ color: 'black', fontSize: '23px' }} />
                        </div> */}
                        <div className="w-full relative md:w-1/2 h-5 p-0 m-0  flex justify-end">

                            <div id="dropdown" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                                    <li>
                                        <a href="#" className="block px-4 py-2 hover: dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
                                    </li>
                                    <li>
                                        <a href="#" className="block px-4 py-2 hover: dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
                                    </li>
                                    <li>
                                        <a href="#" className="block px-4 py-2 hover: dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
                                    </li>
                                    <li>
                                        <a href="#" className="block px-4 py-2 hover: dark:hover:bg-gray-600 dark:hover:text-white">Sign out</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="flex justify-center w-full p-0 items-center flex-wrap">
                        <div className="w-full m-auto h-100 ">
                            <LineChart
                                dateFrom={this.state.dateFromYearly}
                                dateTo={this.state.dateToYearly}
                                legend={"Partner's Analytics for Current year"} rate={'Year'} className="w-auto max-h-40"
                                dataLoginApi={api.getYearlyDownloadsAndLogins}
                                handleDownloadYear={this.handleDownloadYear}
                                ranger={'year'} />
                        </div>

                    </div>
                </div>
            </div>
        </>
        )
    }
}