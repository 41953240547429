import React, { Component } from 'react';
import { AiOutlineSearch } from "react-icons/ai";
import { FaSlidersH, FaRegHandshake } from "react-icons/fa";
import { MdAnalytics } from "react-icons/md";
import { MdOutlineExplore,MdPeople } from "react-icons/md";
import { BiImages, BiLinkExternal, BiLoaderAlt } from "react-icons/bi";
import { GiWorld } from "react-icons/gi";
import { AiOutlineMenu, AiOutlineHome } from "react-icons/ai";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { api } from "../Settings";
import Drawer from "./Drawer";

import logo from './../assets/logo.png';

const navigation = [
  {
      label: "Home",
      url: "/",
      icon: <AiOutlineHome />,
      target:"_self" 
  },
    {
     // url: "/digital-assets",
      url: "/analytics",
      label: "Analytics",
      icon: <MdAnalytics />,
      target:"_self" 
  },
  // {
  //     url: "/explore-dilmah",
  //     label: "Explore Dilmah",
  //     icon: <MdOutlineExplore />,
  //     target:"_self" 
  // },
  // {
  //     url: "/partners",
  //     label: "Partners",
  //     icon: <FaRegHandshake />,
  //     target:"_self" 
  // },
  // {
  //    // url: "/digital-assets",
  //     url: "/collections",
  //     label: "Digital Assets",
  //     icon:  <BiImages />,
  //     target:"_self" 
  // },
//   {
//     url: "/dilmah-universe",
//    // url: "https://www.dilmahtea.com/dilmah-online",
//     label: "Dilmah Universe",
//     type: "extranal",
//     icon:  <GiWorld />,
//   //  target:"_blank" 
// },
 /*  {
      url: "/people",
      label: "People",
      icon: <MdPeople />,
      target:"_self" 
  }, */
];

function withParams(Component) {
  return props => <Component {...props} navigation={useNavigate()} location={useLocation()} />;
}


let timer;
class Header extends Component {

    constructor (props) {
        super(props)

        this.state = {
            active: false,
            isLoading: false,
            isOpen: false,
            searchparams: "",
            searchResults: []
        };
  
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
        this.getResults = this.getResults.bind(this);
    }

    componentWillReceiveProps(nextProps) {

      const { state } = nextProps.location;
      const { val } = state || {};

      this.setState({ searchparams: val });

    }

    toggleDrawer() {
        const currentState = this.state.active;
        this.setState({ active: !currentState });
    }

    handleCallback = (childData) =>{
      //console.log(childData);
      this.setState({active: childData.show})
    }

    handleChange(event) {
      const target = event.target;
      const val = (target.value) ? target.value : "";
      const name = target.name;
      this.setState({ [name]: val });

      clearTimeout(timer);

      if(val){
        timer = setTimeout(() => {
          this.getResults( val );
        }, 250);
      }else{
        this.setState({  isOpen: false })
      }

    }

    handleKeyDown (event) {
      const { navigation } = this.props;
      if (event.key === 'Enter') {

        const target = event.target;
        const val = (target.value) ? target.value : "";
        const name = target.name;

        navigation("/digital-assets", { state: { val } });
        this.setState({ [name]: val });
      }

    };


    handleItemClick (event) {
        const { navigation } = this.props;
        const val = event.currentTarget.getAttribute("data-value");
        navigation("/digital-assets", { state: { val } });
        this.setState({  isOpen: false })
    };


    getResults( q ){

      this.setState({ searchResults:[], isLoading: true, isOpen: true })

      axios.get(api.getSearchSuggestions, { params: { searchPhrase: q }} ).then(response => {
        this.setState({ searchResults: response.data.items });
        this.setState({  isLoading: false })
      });

    }

    render() {
      
      return (
        <>
          <header className="flex shadow fixed top-0 left-0 w-full z-10 bg-white">
            <div className="container mx-auto xl:px-8 pt-4 flex">
            <div className="header-left w-full lg:w-auto flex items-center pb-4">
              <a onClick={this.toggleDrawer}  className="side-menu-trigger text-primary text-2xl cursor-pointer"> <AiOutlineMenu /></a>
              <NavLink to="/"><img className="logo mx-2 md:mx-8 lg:mx-4 xl:mx-12 w-24 md:w-auto" src={logo} /></NavLink>
              
              {/* <div className="search flex items-center relative bg-gray-200 rounded-lg mx-auto">
                <a className="search-icon text-xl text-gray-400 ml-2"><AiOutlineSearch /></a>
                <input
                  className="search-input bg-transparent  p-3 w-40  md:w-80 lg:w-60 xl:w-80 h-10 pl-4 outline-none focus:outline-none overflow-hidden truncate"
                  placeholder="Search..."
                  name="searchparams" 
                  value={this.state.searchparams || ''} 
                  onChange = {this.handleChange}
                  onKeyDown={this.handleKeyDown}
                />
                {
                  this.state.isOpen &&
                  <div className='absolute left-0 top-full shadow bg-white w-full px-2 text-body'>
                  {
                    this.state.isLoading &&
                    <span className="p-2 flex items-center cursor-default text-body/50 text-sm">
                        <BiLoaderAlt className="animate-spin h-5 w-5 mr-2"  /> Loading...
                    </span>
                  }
                  {
                    this.state.searchResults &&  this.state.searchResults.length > 0 &&
                    <>
                      {this.state.searchResults.map((item, index) => {
                            return (
                            <span key={index} className='px-2 block cursor-pointer hover:text-primary' data-value={item} onClick={(e) => this.handleItemClick(e)} >{item}</span>
                            )
                      })}    
                    </> 
                  }
                  </div>
                }
                <a className="filter-icon ml-auto mr-2 text-xl text-gray-400"><FaSlidersH /></a>
              </div> */}
      
            </div>
            <div className="header-right  ml-auto hidden lg:flex items-center text-body">
                {navigation.map((item, index) => {
                  return (
                    <div key={index} className="group">
                    {item.target !== '_blank'        ? 
                      <NavLink target={item.target} to={{pathname: item.url}}  className={({ isActive }) =>  isActive ? 'text-primary px-2 xl:px-3  border-b-4 pt-2 pb-4 border-body/50  transition-all duration-300 block' : 'px-2 xl:px-3 cursor-pointer text-body group-hover:text-black transition-all duration-300 border-b-4 pt-2 pb-4 border-transparent block' }>
                        <div className="flex justify-center">
                          <div className="menu-icon text-2xl opacity-50">
                            <div>
                            {item.icon}
                            </div>
                          </div>
                        </div>
                        <div className="menu-text text-xs pt-1 ">
                        {item.label}
                        </div>
                    </NavLink> : 
                    <a target={item.target} href={item.url}  className="px-2 xl:px-3 cursor-pointer text-body group-hover:text-black transition-all duration-300 border-b-4 pt-2 pb-4 border-transparent block">
                     <div className="flex justify-center">
                       <div className="menu-icon text-2xl opacity-50">
                         <div>
                         {item.icon}
                         </div>
                       </div>
                     </div>
                     <div className="menu-text text-xs pt-1 flex items-center">
                            {item.label} <BiLinkExternal className='ml-1 text-base text-body/50' />
                     </div>
                   </a>      
                    }
                    </div>
                    
                   
                  );
                })}        
            </div>
            </div>   
          </header>
          <Drawer show={this.state.active} drawerCallback={this.handleCallback} />
          {
            this.state.active &&
            <div onClick={this.toggleDrawer}  className='bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30'></div>
          }
        </>
    
      );
    }
}


export default withParams(Header);
  
  