import React, { useEffect, useState, createElement } from 'react';
import axios from 'axios';
// import { MdDownload, MdKeyboardArrowDown } from "react-icons/md";
import * as icons from 'react-icons/md'
import { api } from '../../Settings'
import "./Layout.scss"
import "./AdminLinks.scss"

export default function AdminLinks(props) {

    const [linksState, setLinksState] = useState([])

    useEffect(() => {
        handleRequest();
    }, [])

    const handleRequest = () => {

        // that.setState({ isLoading: true, isError: false })
        axios.get(api.getAdminLinks).then(response => {
            const { data } = response.data
            // this.handleData(data)
            // this.setState({ isLoading: false });
            setLinksState(data)
        })
            .catch(function (error) {
                // that.setState({ isLoading: false, isError: true })
            })
    }

    return (
        <>
            <div className='w-full mb-8'>
                <h2 className="text-left text-4xl">Admin Links</h2>
            </div>
            <div className="flex flex-col sm:flex-col w-90 mb-16">
                {
                    (linksState.length <= 0) ? (<p>Loading...</p>) : (
                        <>
                            <ul className="sm:w-full grid grid-cols-2 gap-8 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-2">
                                {
                                    linksState.map((links) => {
                                        return (
                                            // <div key={links.label} className="flex flex-col items-left sm:flex-col mb-2">
                                            //     <div className="sm:w-96">
                                            //         <h2 className="text-lg font-mono font-semibold align-center text-gray-700 lg:text-xl sm:px-0 xl:px-0 dark:text-gray-400 text-left mb-4">{links.label}</h2>
                                            //     </div>
                                            <>
                                                {
                                                    links.data.map((row) => {
                                                        return <li key={row.label} className=''>
                                                            <a href={row.url} target="_blank" className="bg-white shadow flex h-full min-h-[9rem] flex-col no-underline text-primary hover:text-white py-2 px-4 rounded-lg border border-primary hover:bg-primary flex items-center w-200 h-50 group">
                                                                {createElement(icons[row.icon], {
                                                                    key: row.label,
                                                                    className: "text-white-500 m-1.5 mr-2 mt-2 text-5xl ml-2 hover:text-white-800"
                                                                },
                                                                )}
                                                                <span className="text-white-500 text-center text-lg ml-2 hover:text-white-800">{row.label}</span>
                                                                <span className="text-black/50 ml-2 hover:text-red group-hover:text-white">{links.label.slice(0, -1)}</span>
                                                            </a>
                                                        </li>
                                                    })
                                                }
                                            </>
                                            // </div>
                                        )
                                    })
                                }
                            </ul>
                        </>
                    )
                }
            </div>
        </>
    );
}
