import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function withParams(Component) {
  return props => <Component {...props} navigation={useNavigate()} />;
}

class LineChart extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isError: false,
      labels: [],
      backgrounds: [],
      logins: [],
      dateFrom: this.props.dateFrom,
      dateTo: this.props.dateTo
    }

    this.options = {
      title: {
        display: false,
        text: 'Chart.js Line Chart'
      },
      tooltips: {
        mode: 'label',
      },
      hover: {
        mode: 'nearest',
        intersect: true
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            boxWidth: 100,
            padding: 30,
          },
          position: 'bottom',
          align: 'end'
        },
        title: {
          display: false,
          text: this.props.legend,
        },

      },
      scales: {
        y: {
          display: true,
          title: {
            display: true,
            text: 'value',
            font: {
              size: 14 //this change the font size
            }
          },
          grid: {
            display: false,
          },
        },
        x: {
          display: true,
          title: {
            display: true,
            text: this.props.rate,
            font: {
              size: 14 //this change the font size
            }
          },
          grid: {
            display: false,
          },
        }
      }
    };
    this.handleData = this.handleData.bind(this)
    this.handleRequest = this.handleRequest.bind(this)

  }

  componentDidMount() {
    const that = this;
    that.setState({ isLoading: true, isError: false })
    const response = axios.get(this.props.dataLoginApi).then(response => {
      const { data } = response.data
      this.setState({
        labels: data.map(dat => dat.date),
        downloads: data.map(dat => dat.downloads),
        logins: data.map(dat => dat.logins)
      })

      this.setState({ isLoading: false });
    })
      .catch(function (error) {
        that.setState({ isLoading: false, isError: true })
      })
  }

  componentDidMount() {
    this.handleRequest()

  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.state.dateFrom == this.props.dateFrom) && (this.state.dateTo == this.props.dateTo)) {
      return
    }

    this.handleDateRangeRequest()
  }

  handleDateRangeRequest() {
    const that = this;

    // that.setState({ isLoading: true, isError: false })
    const { navigation } = this.props;
    axios.get(this.props.dataLoginApi, { params: { std: this.props.dateFrom, end: this.props.dateTo } }).then(response => {
      const { data } = response.data
      this.handleData(data)
    })
    .catch(function (error) {
      navigation("/logout");
      navigation(0);
      that.setState({ isLoading: false, isError: true })
    })
  }

  handleRequest() {

    const that = this;
    const { navigation } = this.props;
    axios.get(this.props.dataLoginApi).then(response => {
      const { data } = response.data

      if (this.props.ranger == 'Month'){
        this.props.handleDownloadMonth(data)
      } else {
        this.props.handleDownloadYear(data)
      }

      this.handleData(data)
    })
    .catch(function (error) {
      navigation("/logout");
      navigation(0);
      that.setState({ isLoading: false, isError: true })
    })

  }

handleData(data) {
  this.setState({
    labels: data.map(dat => dat.date),
    downloads: data.map(dat => dat.downloads),
    logins: data.map(dat => dat.logins),
    dateFrom: this.props.dateFrom,
    dateTo: this.props.dateTo
  })
}

  handleData(data) {
    this.setState({
      labels: data.map(dat => dat.date),
      downloads: data.map(dat => dat.downloads),
      logins: data.map(dat => dat.logins),
      dateFrom: this.props.dateFrom,
      dateTo: this.props.dateTo
    })
  }

  render() {
    return (
      <div style={{ position: "relative", margin: "auto" }} className="flex justify-center">
        <Line options={this.options}
          width={1000} height={300}
          data={{
            labels: this.state.labels,
            datasets: [
              {
                fill: true,
                label: 'Downloads',
                data: this.state.downloads,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                lineTension: 0.4,
              },
              {
                fill: true,
                label: 'Logins',
                data: this.state.logins,
                borderColor: 'rgb(148,203,140)',
                backgroundColor: 'rgba(148,203,140, 0.5)',
                lineTension: 0.4,
              },
            ],
          }} />
      </div>
    )
  }

}

export default withParams(LineChart);

