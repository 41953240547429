
const ProtectedRouteAnalytics = () => {
    try{
        
        const dash_permission = JSON.parse(localStorage.getItem('_userData'))
        console.log(dash_permission.dash_permissions.DASHBOARD_MANAGE)
        return dash_permission.dash_permissions.DASHBOARD_MANAGE
    } catch (e) {
        return false
    }
}

const ProtectedRouteAnalyticsRemoveDrawer = (navigation) => {
    try{
        const dash_permission = JSON.parse(localStorage.getItem('_userData'))
        if (!dash_permission.dash_permissions.DASHBOARD_MANAGE){
            const filteredItems = navigation.filter(item => item.label.trim() !== 'Analytics');
            return filteredItems
        } else {
            return navigation
        }
    } catch (e) {
        return navigation
    }

}

export { ProtectedRouteAnalytics, ProtectedRouteAnalyticsRemoveDrawer };
