import React, { Component} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import Header from "./../../compornents/Header";
import Footer from "./../../compornents/Footer";
import Layout from "./Layout";
import ApiError from "./../../compornents/ApiError";
import Loader from "./../../compornents/Loader";
import { api } from "../../Settings";

export default class Analytics extends Component{

    constructor (props) {
        super(props)

        this.state = {
          isLoading: false,
          isError: false,
          componentData: []
        }
    }

    componentDidMount () {
        const that = this;
        that.setState({ isLoading: true, isError: false })
        axios.get(api.getHome).then(response => {
          this.setState({
            componentData: response.data.components
          })
          this.setState({ isLoading: false });
        })
        .catch(function (error) {
          that.setState({ isLoading: false, isError: true  })
        })
    }


    render(){
        const { componentData, isLoading, isError } = this.state;
        return (
          <HelmetProvider>
            <div>
            <Helmet>
              <title> DPN - Home </title>
            </Helmet>
            <Header/>
            <div className="container min-h-screen mx-auto mt-[120px] lg:flex xl:px-8">
              <div className="lg:w-full">
                  {isLoading ? <Loader /> : <>
                      <Layout/>
                  </>}
                  {isError && <ApiError />}        
              </div>

            </div>
            <Footer />
            </div>
            </HelmetProvider>
        )
      }

}

