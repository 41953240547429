const settings = {};

const api = {
    getSettings: process.env.REACT_APP_API_URL + '/CommSettings.json',   
    getHome: process.env.REACT_APP_API_URL + '/CommHome.json',   
    getAssetFilters: process.env.REACT_APP_API_URL + '/CommAssetFilters.json',   
    getAssetTags: process.env.REACT_APP_API_URL + '/CommAssetTags.json',   
    getAssetDirectoryTree: process.env.REACT_APP_API_URL + '/CommAssetDirectoryTree.json',   
    getCollectionAssetFilters: process.env.REACT_APP_API_URL + '/CommCollectionAssetFilters.json',   
    getCollectionDirectoryTree: process.env.REACT_APP_API_URL + '/CommCollectionDirectoryTree.json',   
    getFeaturedAssets: process.env.REACT_APP_API_URL + '/CommFeaturedAssets.json',   
    getAssets: process.env.REACT_APP_API_URL + '/CommAssets.json',   
    getCollections: process.env.REACT_APP_API_URL + '/CommCollections.json',   
    getCollectionDetail: process.env.REACT_APP_API_URL + '/CommCollectionDetail.json',   
    getCollectionDetailItems: process.env.REACT_APP_API_URL + '/CommCollectionDetailItems.json',   
    getAssetItemDetail: process.env.REACT_APP_API_URL + '/CommAssetItemDetail.json',
    getCollectionAssetItemDetail: process.env.REACT_APP_API_URL + '/CommCollectionAssetItemDetail.json',
    getExploreDilmah: process.env.REACT_APP_API_URL + '/CommExploreDilmah.json',
    getPeople: process.env.REACT_APP_API_URL + '/CommPeople.json',
    getNews: process.env.REACT_APP_API_URL + '/CommNews.json',
    getNewsItems: process.env.REACT_APP_API_URL + '/CommNewsItems.json',
    getNewsDetails: process.env.REACT_APP_API_URL + '/CommNewsDetails.json',
    getDailyDownloadsAndLogins: process.env.REACT_APP_API_URL + '/CommSummaryDaily.json',
    getMonthlyDownloadsAndLogins: process.env.REACT_APP_API_URL + '/CommSummaryMonthly.json',
    getYearlyDownloadsAndLogins: process.env.REACT_APP_API_URL + '/CommSummaryYearly.json',
    getEvents: process.env.REACT_APP_API_URL + '/CommEvents.json',
    getEventsItems: process.env.REACT_APP_API_URL + '/CommEventsItems.json',
    getEventDetails: process.env.REACT_APP_API_URL + '/CommEventDetails.json',
    getPartnerFeeds: process.env.REACT_APP_API_URL + '/CommPartnerFeeds.json',
    getPartnerFeedItems: process.env.REACT_APP_API_URL + '/CommPartnerFeedItems.json',
    getDilmahUniverse: process.env.REACT_APP_API_URL + '/CommDilmahUniverse.json',
    getFeaturedNews: process.env.REACT_APP_API_URL + '/CommFeaturedNews.json',
    getFeaturedEvents: process.env.REACT_APP_API_URL + '/CommFeaturedEvents.json',
    getNewsTags: process.env.REACT_APP_API_URL + '/CommNewsTags.json',
    getFeaturedRecipes: process.env.REACT_APP_API_URL + '/CommFeaturedRecipes.json',
    getRecipeItems: process.env.REACT_APP_API_URL + '/CommRecipeItems.json',
    getRecipeChefs: process.env.REACT_APP_API_URL + '/CommRecipeChefs.json',
    getRecipeRanges: process.env.REACT_APP_API_URL + '/CommRecipeRanges.json',
    getRecipeVarities: process.env.REACT_APP_API_URL + '/CommRecipeVarities.json',
    getRecipeDetails: process.env.REACT_APP_API_URL + '/CommRecipeDetails.json',
    getAdminLinks: process.env.REACT_APP_API_URL + '/CommAdminLinks.json',
    getPartners: process.env.REACT_APP_API_URL + '/CommPartners.json',
    getAssetBreadcrumb: process.env.REACT_APP_API_URL + '/CommAssetBreadcrumb.json',
    getFAQ: process.env.REACT_APP_API_URL + '/CommFaq.json',
    getFAQItems: process.env.REACT_APP_API_URL + '/CommFaqItems.json',
    getForum: process.env.REACT_APP_API_URL + '/CommForum.json',
    getForumFeedItems: process.env.REACT_APP_API_URL + '/CommForumFeedItems.json',
    getForumDetails: process.env.REACT_APP_API_URL + '/CommForumDetails.json',
    getSearchSuggestions: process.env.REACT_APP_API_URL + '/CommSearchSuggestions.json',
    getCertifications: process.env.REACT_APP_API_URL + '/CommCertifications.json',
    getCertificationList: process.env.REACT_APP_API_URL + '/CommCertificationList.json',
    getCertificationDetails: process.env.REACT_APP_API_URL + '/CommCertificationDetails.json',
    getRegistrationAddtionalFields: process.env.REACT_APP_API_URL + '/CommRegistrationAddtionalFields.json',
    getCountryList: process.env.REACT_APP_API_URL + '/CommCountryList.json',
    getCollectionSharableLink: '/dad/api/v1/get-collection-sharable-link',
    getAssetSharableLink: '/dad/api/v1/share/get-sharable-link',
    getAnalyticsURI: 'index.php?module=Widgetize&action=iframe&moduleToWidgetize=Dashboard&actionToWidgetize=index&idSite=48&period=year&date=today',
    bulkFileUpload: '/mediaManager/media/bulk-file-upload',
    forumAddDiscussions: '/forum/api/v1/add-discussions',
    forumEditDiscussions: '/forum/api/v1/edit-discussion',
    forumDeleteDiscussion: '/forum/api/v1/delete-discussion',
    forumChangeFavoriteStatus: '/forum/api/v1/change-favorite-status',
    forumGetDiscussionContent: '/forum/api/v1/get-discussion-content',
    forumGetDiscussionContentTemp: process.env.REACT_APP_API_URL +  '/get-discussion-content.json',
};

export { api, settings };

