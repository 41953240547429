import React, { Component } from 'react';
import { FiEdit } from 'react-icons/fi';
const userData =  JSON.parse(localStorage.getItem("_userData"));
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 


class FAQBox extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          data: []
        }

        this.imageAlign = props.imageAlign;

    }
    
    static getDerivedStateFromProps(props, state) {
        return {data: props.data };
    }

    render() {
        const { data } = this.state;
        return (
        <>
        { data && 
            <div className="mb-8 md:mb-16 text-center p-4 md:py-6 md:px-8 bg-primary/30 relative">
                <div className="content-box lg:px-20">
                    <h2 className="text-2xl md:text-3xl mb-6 font-semibold ">{data.title}</h2>
                    <div className="textlg-2xl md:text-2xl" dangerouslySetInnerHTML={{__html : data.description}}></div>
                    { data.btnAction && 
                        <a href={data.btnAction} className="inline-block mt-4 btn bg-primary shadow-md py-3 px-8 rounded-md text-sm text-white hover:shadow-lg hover:opacity-90">
                        {data.btnLabel}
                        </a>
                    }
                </div>
                {
                    EDIT_ACCESS && data.editLink &&
                    <a href={data.editLink} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm'><FiEdit className='text-lg' title="Edit" /></a>
                }
            </div>
        }
        </>
        );
    }
}

export default FAQBox;